<template>
    <el-container class="body">
        <pub-header  class="el-menu-header"></pub-header>
        <el-container>
            <el-main class="el-main-box">
                <el-empty v-if="none_arg" description="暂无数据" ></el-empty>
                
                <el-table
                    :data="tableData"
                    stripe
                    style="width: 90%; position: absolute; left: 5%; top: 10%">
                    <el-table-column
                    prop="id"
                    label="ID"
                    v-if=false>
                    </el-table-column>
                    <el-table-column
                    prop="brand"
                    label="品牌">
                    </el-table-column>
                    <el-table-column
                    prop="area"
                    label="地区">
                    </el-table-column>
                    <el-table-column
                    prop="hotel_name"
                    label="酒店">
                    </el-table-column>
                    <el-table-column
                    prop="code"
                    label="代码">
                    </el-table-column>
                    <el-table-column
                    label="操作">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="danger" icon="el-icon-delete" circle></el-button>
                    </template>
                    </el-table-column>
                </el-table>
                <el-dialog :visible.sync="dialogVisible" class="submitPut">
                    <el-form label-width="80px" :model="newCode" :rules="newCode" ref="newCode">
                        <el-form-item label="酒店集团">
                            <el-select v-model="newCode.brand" placeholder="请选择" @change="handleChangeBrand">
                                <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="省份">
                            <el-select @change="handleChangeProvince" v-model="provinceText" filterable placeholder="请输入省份名称查询">
                                <el-option v-for="item in provinceData" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="城市">
                            <el-select v-model="newCode.area" filterable placeholder="请输入城市名称查询" @change="handleChangeBrand">
                                <el-option v-for="item in cityData" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="酒店名称">
                            <el-select v-model="newCode.hotel_name" placeholder="请选择" @change="newCode.hotel_code = hotel_data[newCode.hotel_name]">
                                <el-option
                                v-for="item in hotel_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="酒店简称">
                        <el-input v-model="newCode.hotel_code" style="width: 60%;" :disabled="true"></el-input>
                        </el-form-item>
                        <el-form-item label="代码">
                        <el-input v-model="newCode.code" style="width: 80%;"></el-input>
                        </el-form-item>
                        <el-form-item>
                        <el-button type="primary" @click="submitCode">提交</el-button>
                        <el-button @click="changeState">取消</el-button>
                        </el-form-item>
                    </el-form>
                </el-dialog>
                <el-dialog :visible.sync="dialogVisible_hotel" class="submitPut">
                    <el-form label-width="80px" :model="newHotel" :rules="newHotel" ref="newHotel">
                        <el-form-item label="酒店集团">
                            <el-select v-model="newHotel.brand" placeholder="请选择">
                                <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="省份">
                            <el-select @change="handleChangeProvince" v-model="provinceText" filterable placeholder="请输入省份名称查询">
                                <el-option v-for="item in provinceData" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="城市">
                            <el-select v-model="newHotel.area" filterable placeholder="请输入城市名称查询">
                                <el-option v-for="item in cityData" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="酒店名称">
                        <el-input v-model="newHotel.hotel_name" style="width: 80%;"></el-input>
                        </el-form-item>
                        <el-form-item label="酒店简称">
                        <el-input v-model="newHotel.hotel_code" style="width: 80%;"></el-input>
                        </el-form-item>
                        <el-form-item>
                        <el-button type="primary" @click="submitHotel">提交</el-button>
                        <el-button @click="dialogVisible_hotel = false">取消</el-button>
                        </el-form-item>
                    </el-form>
                </el-dialog>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import {regionData, CodeToText} from 'element-china-area-data'
import pubHeader from "./header.vue"
export default {
    data(){
        return{
            activeIndex: "2",
            none_arg: false,
            dialogVisible: false,
            dialogVisible_hotel: false,
            options: [
                {value: "IHG", label: "IHG"},
                {value: "希尔顿", label: "希尔顿"},
                {value: "HYATT", label: "HYATT"},
                {value: "万豪", label: "万豪"},
                {value: "香格里拉", label: "香格里拉"}
            ],
            hotel_options: [],
            hotel_data: {},
            tableData: [],
            newCode: {
                hotel: '',
                code: '',
                hotel_name: '',
                area: "",
                brand: "",
                
            },
            newHotel: {
                'brand': "",
                'area': "",
                'hotel_name': "",
                'hotel_code': '',
            },
            value: '',
            provinceData: regionData,
            cityData: [],
            provinceText: '',
            cityText: ''

        }
    },
    components: {pubHeader},
    mounted:function(){
        this.getHotelCode();
    },
    methods: {
        changeSelectHotel(val){
            this.$forceUpdate();
            this.getHotelCode(val)
        },
        getHotelCode(hotel){
            this.$axios.get( '/api/hotel/findCode', {
                params: {'hotel': hotel}
            }).then(res => {
                if(res.data.state == 200){
                    if (res.data.codes.length == 0){
                        this.$message.success("暂无数据");
                    }
                    this.tableData = res.data.codes;
                    
                }else{
                    this.$message.success(res.data.msg);
                }
            })
            
        },
        handleClick(row){
            this.$axios.get('/api/hotel/delCode', {
                params: {"id": row.id}
            }).then(res => {
                if(res.data.state == 200){
                    this.getHotelCode(this.value);
                }else{
                    this.$message.error(res.data.msg);
                }
            })

        },
        changeState(){
            if (this.dialogVisible){
                this.dialogVisible = false;
            }else{
                this.dialogVisible = true;
            }
            
        },
        submitCode(){
            if(this.newCode.hotel_code && this.newCode.code){
                this.$axios.get('/api/hotel/addCode', {
                    params: this.newCode
                }).then(res =>{
                    if(res.data.state == 200){
                        this.$message.success("添加成功");
                        this.dialogVisible = false;
                        this.getHotelCode(this.value);
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })
            }
            this.newCode.code = this.newCode.hotel = ''
        },
        handleChangeProvince(value){
            this.cityData = []
            this.newHotel.hotel = ''
            const province = CodeToText[value]
            if (province === '北京市' || province === '天津市' || province === '上海市' || province === '重庆市' ||
                province === '香港特别行政区' || province === '澳门特别行政区') {
                const city = { value: value, label: province }
                this.cityData.push(city)
            } else {
                const childList = this.provinceData.find(i => i.value === value).children
                console.log(childList);
                this.cityData.push(...childList)
            }
        },
        submitHotel(){
            this.newHotel.area = CodeToText[parseInt(this.newHotel.area)];
            if(this.newHotel.brand && this.newHotel.area && this.newHotel.hotel_code){
                this.$axios.get('/api/hotel/addCode', {
                    params: this.newHotel
                }).then(res => {
                    if(res.data.state == 200){
                        this.$message.success("添加成功");
                        this.dialogVisible_hotel = false;
                        this.getHotelCode(this.value);
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })
            }else{
                this.$message.info("请补全信息");
            }
        },
        handleChangeBrand(){
            this.newCode.hotel_name = this.newCode.hotel_code = '';
            this.newCode.area = CodeToText[parseInt(this.newCode.area)];
            if(this.newCode.brand){
                this.$axios.get('/api/hotel/getHotelBy', {
                    params: this.newCode
                }).then(res =>{
                    if(res.data.state == 200){
                        const hits = res.data.codes;
                        this.hotel_data = {};
                        this.hotel_options = [];
                        for (var i=0; i<hits.length; i++){
                            const item = hits[i]
                            this.hotel_data[item.hotel_name] = item.hotel_code;
                            this.hotel_options.push({value: item.hotel_name, label: item.hotel_name})
                        }

                    }
                })
            }
        }
        
    }
}
</script>

<style scoped>
    .body{
        height: 100%;
        background: #f7f8f9;
    }
    .el-menu-header{
        width: 100%;
    }
    .el-main-box{
        width: 80%;
        height: 85%;
        position: absolute;
        left: 10%;
        top: 8%;
        background: white;
    }
    .submitPut{
        position: absolute;
        left: 20px;
        top: -30px;
    }
</style>
